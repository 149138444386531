<template>
  <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #ccc; display: flex">
    <div style="width: 20%; padding-left: 30px; font-weight: bold; color: dodgerblue">中小企业危险废物二维码生成系统</div>
    <div style="flex: 1"></div>
    <div>
      <el-dropdown>
        <span class="el-dropdown-link" style="margin-right: 25px;">
          <el-avatar :size="30" :src="img_avatar" style="position: relative; top: 10px"></el-avatar>
           {{ user.username }}
          <i class="el-icon-arrow-down el-icon--right" style="margin-left: 0px;"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleCompanyCode()">我的企业二维码</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/my-order')">我的订单</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/person')">修改手机号</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/password')">修改密码</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/')">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>

  <el-dialog title="企业信息二维码" v-model="dialogVisibleWxQrCode" :close-on-click-modal="false" width="30%">
    <el-row>
      <span style="color: gray;font-size: 12px;">备注：鼠标右键点击下载二维码</span>
    </el-row>
    <img :src="user.qrcode" alt="企业信息二维码"  style="width:100%;height: 100%; ">
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleWxQrCode = false">取 消</el-button>
          </span>
    </template>
  </el-dialog>


</template>

<script>
import img_avatar from '@/assets/images/user.jpg';
export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      img_avatar,
      dialogVisibleWxQrCode: false,
    }
  },
  created() {
  },
  methods: {
    handleCompanyCode(){
      this.dialogVisibleWxQrCode = true;
    },

  }
}
</script>

<style scoped>

</style>
