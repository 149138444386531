<template>
<!--  <div class="app-foot" style="height: 60px; width: 100%; color: #939393;font-size: 12px;text-align: center;">
    Copyright ©  https://www.qzqcode.com. All Rights Reserved 沧州清之青环保科技有限公司 冀ICP备2024092179号-1
  </div>-->
  <div  class="app-foot"  style="height: 60px; width: 100%; color: #939393;font-size: 12px;text-align: center;">
    <img src="../assets/images/gaba.png" style="float:left;"/>
    Copyright ©  https://www.qzqcode.com. All Rights Reserved 沧州清之青环保科技有限公司
    <a target="_blank"
       href="https://beian.miit.gov.cn/"
       style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
      <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-decoration: underline;">
        冀ICP备2024092179号-1</p></a>
    <a target="_blank"
       href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13090002000227"
       style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
      <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-decoration: underline;">
        冀公网安备13090002000227号</p></a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {

  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.app-foot {
  /* footer 固定在页面底部 */
  min-height: 35px;
  background-color: #eeeeee;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
