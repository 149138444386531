<template>
  <div class="layout-container">
    <el-header style="padding: 0px;">
      <Header :user="user"/>
    </el-header>
    <el-container >
      <!--侧边栏-->
      <el-aside >
        <Aside />
      </el-aside>
      <!--内容区域-->
      <el-main style="min-height: calc(100vh - 120px);">
        <router-view style="flex: 1" @userInfo="refreshUser"/>
      </el-main>
    </el-container>
    <el-footer style="padding: 0px;height: 60px;">
      <Footer />
    </el-footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Aside from "@/components/Aside";
import {getLoginDetail} from "@/api/login";

export default {
  name: "Layout",
  components: {
    Header,
    Aside,
    Footer
  },
  data() {
    return {
      user: {}
    }
  },
  created() {
    this.refreshUser()
  },
  methods: {
    refreshUser() {
      let userJson = sessionStorage.getItem("user");
      if(!userJson){
        this.$message.warning("token过期，请重新登录！")
        this.$router.replace('/');
        return;
      }
      let userId = JSON.parse(userJson).id
      // 从后台取出更新后的最新用户信息
      getLoginDetail(userId).then(response=>{
        if (response.code === '0') {
          this.user = response.data;
        }
      });
    }
  }
}
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
}
.el-aside {
  --el-aside-width: 230px;
}

</style>
