import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Layout from "@/layout/Layout";


const routes = [
    {
        path: '/',
        name: 'Introduce',
        component: () => import("../views/Introduce"),
        meta: {
            title: '中小企业危险废物二维码生成器',
            content: {
                keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                description: '本系统包括五大功能：' +
                    '1.可为企业生成危险废物独立二维码。' +
                    '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                    '3.可随时填报危险废物数据信息。' +
                    '4.可查看企业危险废物基本信息情况。' +
                    '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
            }
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/Login"),
        meta: {
            title: '中小企业危险废物二维码生成器',
            content: {
                keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                description: '本系统包括五大功能：' +
                    '1.可为企业生成危险废物独立二维码。' +
                    '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                    '3.可随时填报危险废物数据信息。' +
                    '4.可查看企业危险废物基本信息情况。' +
                    '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
            }
        }
    },
    {
        path: '/find',
        name: 'FindPass',
        component: () => import("../views/FindPass"),
        meta: {
            title: '中小企业危险废物二维码生成器',
            content: {
                keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                description: '本系统包括五大功能：' +
                    '1.可为企业生成危险废物独立二维码。' +
                    '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                    '3.可随时填报危险废物数据信息。' +
                    '4.可查看企业危险废物基本信息情况。' +
                    '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
            }
        }
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import("../views/Info"),
        meta: {
            title: '中小企业危险废物二维码生成器',
            content: {
                keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                description: '本系统包括五大功能：' +
                    '1.可为企业生成危险废物独立二维码。' +
                    '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                    '3.可随时填报危险废物数据信息。' +
                    '4.可查看企业危险废物基本信息情况。' +
                    '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
            }
        }
    },
    {
        path: '/home',
        name: 'Layout',
        component: Layout,
        redirect: "/home",
        children:[
            {
                path: '/home',
                name: 'Home',
                component: () => import("@/views/Home"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/password',
                name: 'Password',
                component: () => import("../views/Password"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/person',
                name: 'Person',
                component: () => import("../views/Person"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/my-order',
                name: 'MyOrder',
                component: () => import("../views/MyOrder"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/order-use',
                name: 'MyOrderInvoice',
                component: () => import("../views/MyOrderInvoice"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/gallery-system',
        name: 'GallerySystem',
        component: Layout,
        redirect: "/gallery-system",
        children:[
            {
                path: '/gallery',
                name: 'Gallery',
                component: () => import("../views/gms/Gallery"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/gallery-file',
                name: 'GalleryFile',
                component: () => import("../views/gms/GalleryFile"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/sys',
        name: 'System',
        component: Layout,
        redirect: "/sys",
        children:[
            {
                path: '/admin',
                name: 'Admin',
                component: () => import("../views/sys/Admin"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/permission',
                name: 'Permission',
                component: () => import("../views/sys/Permission"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/role',
                name: 'Role',
                component: () => import("../views/sys/Role"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/application-system',
        name: 'ApplicationSystem',
        component: Layout,
        redirect: "/application-system",
        children:[
            {
                path: '/news',
                name: 'News',
                component: () => import("../views/ams/News"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/expense',
                name: 'AccountExpense',
                component: () => import("../views/ams/AccountExpense"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/company',
                name: 'Company',
                component: () => import("../views/ams/Company"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/application-describe',
                name: 'ApplicationDescribe',
                component: () => import("../views/ams/ApplicationDescribe"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/describe',
                name: 'Describe',
                component: () => import("../views/ams/Describe"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/declare',
        name: 'declareSystem',
        component: Layout,
        redirect: "/declare",
        children:[
            {
                path: '/own-company-qrcode',
                name: 'OwnCompanyQrcode',
                component: () => import("../views/dms/OwnCompanyQrcode"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/own-company-info',
                name: 'OwnCompanyInfo',
                component: () => import("../views/dms/OwnCompanyInfo"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/own-company-waste',
                name: 'OwnCompanyWaste',
                component: () => import("../views/dms/OwnCompanyWaste"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/own-company-inventory',
                name: 'OwnCompanyInventory',
                component: () => import("../views/dms/OwnCompanyInventory"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/own-company-storage',
                name: 'OwnCompanyStorage',
                component: () => import("../views/dms/OwnCompanyStorage"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/own-company-entrust',
                name: 'OwnCompanyEntrust',
                component: () => import("../views/dms/OwnCompanyEntrust"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/company-declare',
        name: 'CompanyDeclareSystem',
        component: Layout,
        redirect: "/company-declare",
        children:[
            {
                path: '/company-info',
                name: 'CompanyInfo',
                component: () => import("../views/cms/CompanyInfo"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/company-waste',
                name: 'CompanyWaste',
                component: () => import("../views/cms/CompanyWaste"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/company-inventory',
                name: 'CompanyInventory',
                component: () => import("../views/cms/CompanyInventory"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/company-storage',
                name: 'CompanyStorage',
                component: () => import("../views/cms/CompanyStorage"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/company-entrust',
                name: 'CompanyEntrust',
                component: () => import("../views/cms/CompanyEntrust"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
        ]
    },
    {
        path: '/invoice-system',
        name: 'InvoiceSystem',
        component: Layout,
        redirect: "/invoice-system",
        children:[
            {
                path: '/invoice-list',
                name: 'InvoiceList',
                component: () => import("../views/ims/InvoiceList"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },
            {
                path: '/invoice-use',
                name: 'InvoiceDetailList',
                component: () => import("../views/ims/InvoiceDetailList"),
                meta: {
                    title: '中小企业危险废物二维码生成器',
                    content: {
                        keywords: '中小企业危险废物二维码生成器,危险废物,二维码生成器,危险废物二维码生成器,清之青,环保,企业危险废物',
                        description: '本系统包括五大功能：' +
                            '1.可为企业生成危险废物独立二维码。' +
                            '2.系统从数据安全角度考虑、对用户身份进行认证、数据权限控制、并记录企业用户的操作日志。' +
                            '3.可随时填报危险废物数据信息。' +
                            '4.可查看企业危险废物基本信息情况。' +
                            '5. 实现实时填报、扫描二维码实时可查看更新数据，可修改的智能管理系统。'
                    }
                }
            },

        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router
