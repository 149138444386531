import request from '@/utils/request';

//登录
export function userLogin(data) {
  return request({
    url : '/user/userLogin',
    method : 'post',
    data : data
  })
}

//注册
export function userRegister(data) {
  return request({
    url : '/user/register',
    method : 'post',
    data : data
  })
}

//详情
export function getLoginDetail(data) {
  return request({
    url:'/user/getLoginDetail',
    method : 'post',
    data : data
  })
}

//修改密码
export function updateLoginPwd(data) {
  return request({
    url : '/user/updateLoginPwd',
    method : 'post',
    data : data
  })
}

//修改密码
export function updateUserPwd(data) {
  return request({
    url : '/user/updateUserPwd',
    method : 'post',
    data : data
  })
}

//续费时校验手机号
export function verifyPhone(data) {
  return request({
    url : '/user/verifyPhone',
    method : 'post',
    data : data
  })
}

//注册
export function updateUserEndTime(data) {
  return request({
    url : '/user/updateUserEndTime',
    method : 'post',
    data : data
  })
}
