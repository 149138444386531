<template>
  <div style="width: 230px; height: 100%;border-right: 1px solid #e6e6e6;">
    <el-menu
        :default-active="$route.path"
        :unique-opened="true"
        router
    >
      <div  v-for="item in user.permissions">
        <el-menu-item :index="item.path" :key="item.id" v-if="item.parent == 0 && item.children.length == 0 && item.name !== 'Person' && item.name !== 'Password'"  >
          <i :class="item.icon"></i>{{ item.comment }}
        </el-menu-item>
        <el-submenu
            v-if="item.parent == 0 && item.children.length !== 0"
            :index="item.id+''"
            :key="item.id"
        >
          <template #title><i :class="item.icon"></i>{{ item.comment }}</template>
          <el-menu-item style="padding-left: 40px;"
              v-for="child in item.children"
              :index="child.path"
              :key="child.id">
            <i :class="child.icon"></i>{{ child.comment }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="item.id" v-if="!item.children && item.name !== 'Person' && item.name !== 'Password'" >
          <i :class="item.icon"></i>{{ item.comment }}
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>

export default {
  name: "Aside",
  data() {
    return {
      user: {}
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(userStr);
  }
}
</script>

<style scoped>
:deep(.el-submenu .el-menu-item){
  width: 230px !important;
}
.el-aside {
  --el-aside-width: 230px;
}
.el-menu {
  border-right: none;
}
</style>
